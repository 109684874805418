//import Vue from "vue";

const getDefaultState = () => {
  return {
    charts:[],
    merchantProductReport:null

  }
}

const state = getDefaultState();
const mutations = {

  RESET(state) {
    Object.assign(state, getDefaultState())
  },
  SET_CHARTS: (state, newValue) => {
    state.charts = newValue
  },
  SET_MERCHANT_PRODUCT_REPORT: (state, newValue) => {
    state.merchantProductReport = newValue
  }      
}

const actions = {
  reset: ({ commit, state }) => {
    commit('RESET')
    return state.message
  },
  setCharts: ({ commit, state }, newValue) => {
    commit('SET_CHARTS', newValue)
    return state.message
  },
  setMerchantProductReport: ({ commit, state }, newValue) => {
    commit('SET_MERCHANT_PRODUCT_REPORT', newValue)
    return state.message
  }  
}
const getters = {
  charts: state => {
    return state.charts
  },  
  merchantProductReport: state => {
    return state.merchantProductReport
  },      
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
