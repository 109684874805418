//import Vue from "vue";

const util = {
  namespaced: true,
  state: {
    loading: false,
    loadingText: "",
    modalComponent: null,
    showNavDrawer: false,
    showBottomChartSheet: false,
    snackbar: { show:false, message: "", errors: []  },
    leaflet: null
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADINGTEXT(state, payload) {
      state.loadingText = payload;
    },    
    SET_MODAL_COMPONENT(state, payload){
      state.modalComponent = payload;
    },
    SET_NAV_DRAWER(state, payload){
      state.showNavDrawer = payload
    },
    SET_CHART_BOTTOM_SHEET(state, payload){
      state.showBottomChartSheet = payload
    },    
    SET_SNACKBAR(state, payload){
      state.snackbar = payload
    },
    SET_LEAFLET(state, payload) {
      state.leaflet = payload;
    },    
  },
  actions: {
    setLoading({ commit }, data ) {
      commit("SET_LOADING", data.visible);
      commit("SET_LOADINGTEXT", data.text);
    },
    resetLoading({ commit }) {
      commit("SET_LOADING", false);
      commit("SET_LOADINGTEXT", "");
    }, 
    setLeaflet({ commit }, data ) {
      commit("SET_LEAFLET", data);
    },         
    setLoadingText({ commit }, data ) {
      commit("SET_LOADINGTEXT", data);
    },    
    setModalComponent({ commit }, data ) {
      commit("SET_MODAL_COMPONENT", data);
    },  
    setNavDrawer({ commit }, data){
      commit("SET_NAV_DRAWER", data)
    },
    setBottomChartSheet({ commit }, data){
      commit("SET_CHART_BOTTOM_SHEET", data)
    },    
    setSnackbar({ commit }, data){
      commit("SET_SNACKBAR", data)
    }       
  },
  getters: {
    loading: function(state) {
      return state.loading;
    },
    loadingText: function(state) {
      return state.loadingText;
    },    
    modalComponent: function(state) {
      return state.modalComponent;
    },
    showNavDrawer: function(state) {
      return state.showNavDrawer
    },
    showBottomChartSheet: function(state) {
      return state.showBottomChartSheet
    },
    snackbar: function(state) {
      return state.snackbar
    },
    leaflet: function(state) {
      return state.leaflet
    }                  
  }
};
export default util;
