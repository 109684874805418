import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import util from './modules/util'
import filter from './modules/filter'
import chart from './modules/chart'
import target from './modules/target'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      auth: auth,
      util: util,
      filter: filter,
      chart: chart,
      target: target
  }
})