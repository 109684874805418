import Vue from "vue";

export default new (class TargetService {

  async CreateSelection(data) {
    const path = `/api/target/CreateTarget`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: data
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetTargetInfo(id) {
    const path = `/api/target/GetTargetInfo/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetTargetFeatureCollection(id) {
    const path = `/api/target/GetTargetFeatureCollection/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetTargetSalesHeatmap(id) {
    const path = `/api/target/GetTargetSalesHeatmap/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetTargetVisitedMerchantsFeatureCollection(id) {
    const path = `/api/target/GetTargetVisitedMerchantsFeatureCollection/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetTargetCustomersPaginated(targetGroupId, pageNumber, pageSize, search) {
    const path = `/api/target/GetTargetCustomersPaginated/${targetGroupId}?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetTargetProspectsPaginated(targetGroupId, pageNumber, pageSize, search) {
    const path = `/api/target/GetTargetProspectsPaginated/${targetGroupId}?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetIsochrone(lat, lng, minutes, mode) {
    const path = `/api/target/GetIsochrone?lat=${lat}&lng=${lng}&minutes=${minutes}&mode=${mode}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GeometryPostalCodesCSV(data) {
    const path = `/api/target/GeometryPostalCodesCSV`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: data
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }


  async GetMunicipalities(country) {
    const path = `/api/target/Municipalities/${country}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetMunicipalitiesFeatureCollection(countryCode, salesreps, targetGroupId) {
    
    const path = `/api/target/MunicipalitiesFeatureCollection/${countryCode}?salesreps=${salesreps}&targetGroupId=${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error)
      });

    return result;
  }

  async GetAreaStatistics(areaType, areaCodes, targetGroupId) {
    const path = `/api/target/AreaStatistics/${areaType}?targetGroupId=${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: areaCodes
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetMunicipalityCustomerSegmentChart(id) {
    const path = `/api/target/MunicipalityCustomerSegmentChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetMunicipalityMerchantChart(id) {
    const path = `/api/target/MunicipalityMerchantChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetMunicipalityCustomerLevelChart(id) {
    const path = `/api/target/MunicipalityCustomerLevelChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }    

  async GetMunicipalityCustomerSegmentLevelChart(id) {
    const path = `/api/target/MunicipalityCustomerSegmentLevelChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }     

  async GetMerchantStatistics(merchantId, targetGroupId) {
    const path = `/api/target/MerchantStatistics/${merchantId}?targetGroupId=${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async GetMerchantStatisticsChart(merchantId, targetGroupId) {
    const path = `/api/target/MerchantStatisticsChart/${merchantId}?targetGroupId=${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  
  
  async GetMerchantProductSalesReport(merchantId, targetGroupId) {
    const path = `/api/target/MerchantProductSalesReport/${merchantId}?targetGroupId=${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }    

  async GetMerchantProductSalesReportList(targetGroupId) {
    const path = `/api/target/MerchantProductSalesReportList/${targetGroupId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }    

  async GetTargetExcel(id, filter) {
    const path = `/api/target/GetTargetExcel/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      responseType: "blob",
      data: filter
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

      
    return result;
  }  



})();
