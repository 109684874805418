<template>
  <v-app id="app">
    <fullpageloader style="z-index:1000000000000000" />

    <!-- <Navbar style="z-index:500000" v-if="user.loggedIn"/> -->
    <div v-if="user.loggedIn">
    <navbarauthorized  />
    <!-- <navdrawer /> -->
    <snackbar />
    </div>
    <div v-else>
      <navbarunauthorized />
    </div>


    <v-main>
       <transition :duration="200" name="fade" mode="out-in">
      <router-view />
       </transition>
    </v-main>

<chartsheet />
    <v-dialog v-model="showModal" v-if="showModal" persistent scrollable :max-width="modal.width">
 

     <v-card v-if="showModal">
<v-toolbar
          dark
          dense
          color="primary"
        >
          <v-btn
            icon
            dark
              @click="toggleModal"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>{{modal.friendlyname}}</v-toolbar-title> -->
        </v-toolbar>


  
                  <component v-bind:is="modal.component"></component>
          
 
     </v-card>
    </v-dialog>
  </v-app>
</template>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #EBE9E5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
// @ is an alias to /src

import navbarauthorized from "@/components/navigation/NavAppBar.vue";
import navbarunauthorized from "@/components/navigation/NavAppBarUnAuthorized.vue";
import navdrawer from "@/components/navigation/NavDrawer.vue";
import fullpageloader from "@/components/overlays/Loading.vue";
import snackbar from "@/components/overlays/Snackbar.vue";
import exporttargetsummary from "@/components/precis/ExportTargetSummary.vue";
import merchantproductsalesreportnested from "@/components/reports/MerchantProductSalesReportNested.vue"


import chartsheet from  "@/components/overlays/RightSheet"



export default {
  components: {
    navbarauthorized,
    navbarunauthorized,
    navdrawer,
    fullpageloader,
    snackbar,
    exporttargetsummary,
    merchantproductsalesreportnested,
    chartsheet
  },
  computed: {
    loading() {
      return this.$store.getters["util/loading"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    modal() {
      return this.$store.getters["util/modalComponent"];
    },
    showModal() {
      return this.modal !== null;
    },
  },
  methods: {
    toggleModal() {
      this.$store.dispatch("util/setModalComponent", null);
    },
  },
};
</script>

