//import Vue from "vue";
 import targetService from "@/services/TargetService";

const getDefaultState = () => {
    return {
      areas:[],
      areaStatistics: []
    }
  }
  
  const state = getDefaultState();
  const mutations = {
  
    RESET(state) {
      Object.assign(state, getDefaultState())
    },
    SET_AREAS: (state, newValue) => {
      state.areas = newValue
    },
    REMOVE_AREA: (state, item) => {
      
      Window.municipalityFeatureGroup.eachLayer((i) => 
        { 
          i.eachLayer( (n) => {
            // if(n._leaflet_id == item.leafletId){
              if(n.feature.properties.featureId == item){
              n.setStyle({
                fillColor: "gray",
                color: "black",
                weight: 1,
                fillOpacity: 0.2
              });
            }
          })
       
        })

      let index = state.areas.indexOf(i => i.featureId == item)
       state.areas.splice(index, 1)
      // let filtered = state.areas.filter((i) => { return i.featureId !== item.featureId }); 
      // state.areas = filtered
    },    
    CLEAR_AREAS: (state) => {
      state.areas = []
    }        
  }
  
  const actions = {
    clearAreas: ({ commit, state }) => {
      commit('CLEAR_AREAS')
      return state.message
    },
    setAreas: async ({ commit, state, }, data) => {
      commit('SET_AREAS', data.selection)
      state.areaStatistics = await targetService.GetAreaStatistics("municipality", data.selection.map(i => parseInt(i.featureId)), data.targetGroupId);
      return state.message
    },
    updateAreas: async ({ state, }, targetGroupId) => {
      state.areaStatistics = await targetService.GetAreaStatistics("municipality", state.areas.map(i => parseInt(i.featureId)), targetGroupId);
      return state.message
    },

    removeArea: async ({ commit, state }, newValue) => {
      commit('REMOVE_AREA', newValue)
      let index = state.areaStatistics.indexOf(i => i.areaId == newValue)
      state.areaStatistics.splice(index, 1)
      return state.message
    }  
  }
  const getters = {
    areas: state => {
      return state.areas
    },
    areaStatistics: state => {
      return state.areaStatistics
    }       
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  