<template>

  <v-card flat v-if="report">
          <div class="transition-swing text-h5 mb-2">{{report.merchant}}</div>
          <v-row justify="center" >
            <v-col cols="4">
                   <v-card elevation="3" class="mx-2">
           <v-card-title>
              {{report.quantity_Formatted}}
           </v-card-title>           
           <v-card-subtitle>
              Volume R12
           </v-card-subtitle>
         </v-card>  
            </v-col>
            <v-col cols="4">
                          <v-card elevation="3" class="mx-2">
           <v-card-title>
              {{report.revenue_Formatted}}
           </v-card-title>           
           <v-card-subtitle>
              Revenue R12
           </v-card-subtitle>
         </v-card> 
          </v-col>
          </v-row>
          <v-row>
          <v-col>
  <v-data-table
    :headers="headers"
    :items="report.productReportItems"
    :items-per-page="5"
    class="elevation-1"
  ></v-data-table>
  </v-col>
          </v-row>
  </v-card>
</template>

<script>
  export default {
    data () {
      return {
        headers: [
          {
            text: 'Product',
            align: 'start',
            sortable: false,
            value: 'product',
          },
          { text: 'Quantity', value: 'quantity_Formatted' },
          { text: 'Revenue', value: 'revenue_Formatted' },
        ],
      }
    },
    computed: {
        report(){
         return this.$store.getters["chart/merchantProductReport"];
      }   
    }
  }
</script>