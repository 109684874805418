<template>
  <v-snackbar
    
    bottom
    right
    v-model="show"
    :timeout="-1"
    
  >
    {{ snackbar.message }}

    <v-alert outlined  v-for="(e, i) in snackbar.errors" :key="i" type="warning">
        {{ e  }}
    </v-alert>

    <template v-slot:action="{ attrs }">
      <v-btn  text  v-bind="attrs" @click="close"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    show: false,
    value: null,
  }),
  watch:{
      snackbar(){
          this.show = this.snackbar.show
      }
  },
  computed: {
    snackbar() {
      return this.$store.getters["util/snackbar"];
    },
    isError(){
        return this.snackbar.errors == []
    }
  },
  methods: {
    close() {
          const snackbar = { show: false, message: "", errors: []  }
          this.$store.dispatch("util/setSnackbar", snackbar)
    },
  },
};
</script>
