<template>

    <v-app-bar app color="primary" dark dense extension-height="25">
     
     <v-app-bar-title>
       Precis
     </v-app-bar-title>

<template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab to="/home">Home</v-tab>
          <v-tab to="/precis" :disabled="!isInRole('Precis')">Precis</v-tab>
          <v-tab to="/reports" :disabled="!isInRole('Reports')">Merchant sales</v-tab>
          <v-tab to="/sales-executive" :disabled="!isInRole('Reports')">Executive sales</v-tab>
          <v-tab to="/pricing" :disabled="!isInRole('Pricing')">Pricing</v-tab>
          <v-tab to="/salesrep" :disabled="!isInRole('Salesrep')">Salesrep</v-tab>
          <v-tab to="/cims" :disabled="!isInRole('Cims')">CIMS</v-tab>
          <!-- <v-tab to="/merchantdistributionreport">Reports</v-tab> -->
        </v-tabs>
   

</template>


      <v-spacer></v-spacer> 
 <span v-if="user.loggedIn">
{{user.data.userName}}
 </span>
  

      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
   <v-btn icon v-bind="attrs"
          v-on="on"
          @click="OpenAdmin"
          v-if="isAdmin"> 
        <v-icon>mdi-cog</v-icon>
      </v-btn>
</template>
      <span>Admin</span>
    </v-tooltip>
    
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
   <v-btn icon v-bind="attrs"
          v-on="on"
          @click="Logout"> 
        <v-icon>mdi-logout</v-icon>
      </v-btn>
</template>
      <span>Log out</span>
    </v-tooltip>

    </v-app-bar>



</template>

<script>

import accountService from "@/services/AccountService";
import Vue from "vue";
export default {
  components: {
  },
  props: {
    source: String
  },
  data: () => ({
    // drawer: false
  }),
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    isAdmin(){
      return this.$store.getters["auth/isAdmin"];
    }
  },
  methods:{
 setNavDrawer(){
   this.$store.dispatch("util/setNavDrawer", true)
 },
          async Logout() {
             await accountService.Logout()
             this.$store.dispatch("auth/setUser", null);
             this.$store.dispatch('auth/setCredentials', null); //deletes key from local storage
             Vue.prototype.$axios.defaults.headers.common["Authorization"] = null //deletes key from axios auth header
             window.analytics.reset();
     this.$router.replace({
            name: "login"
          });


    },
    OpenAdmin() {
     this.$router.replace({
            name: "admin"
          });
    },
    isInRole(role) {
      return this.user.data.roles.includes(role);
    }    
    }
};
</script>