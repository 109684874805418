<template>
  <v-container>
    <v-row v-if="loading" justify="center" align="center">
      <v-card width="50%" class="pa-10" flat>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      </v-card>
    </v-row>

    <v-row v-else dense justify="center">
      <v-col lg="3" md="6" sm="6" cols="12">
        <v-card elevation="3" class="mx-2" color="info" dark>
          <v-card-title>
            {{ targetInfo.recipients }}
          </v-card-title>
          <v-card-subtitle> Customers </v-card-subtitle>
        </v-card>

        <v-card elevation="3" class="ma-2" color="info" dark>
          <v-card-title> R12 </v-card-title>
          <v-card-subtitle> Period </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col>
        <v-data-table
          :headers="headers"
          :items="report"
          item-key="merchantId"
          show-expand
          class="elevation-1"
          :single-expand="true"
          :expanded.sync="expanded"
          :search="search"
        >
 
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Sales</v-toolbar-title>
              <v-spacer></v-spacer>
                      <v-text-field
          v-model="search"
          label="Search for merchant"
          class="mx-4"
        ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <v-card rounded class="ma-1" flat color="primary" dark>
                <v-simple-table
                  dense
                  class="pa-5"
                  v-if="showProductTable"
                  style="background-color: rgba(0, 0, 0, 0)"
                >
                  <thead>
                    <td>Product</td>
                    <td>Quantity</td>
                    <td>Revenue</td>
                  </thead>
                  <tbody>
                    <tr
                      v-for="p in reportDetailed.productReportItems"
                      :key="p.name"
                    >
                      <td>{{ p.product }}</td>
                      <td>{{ p.quantity_Formatted }}</td>
                      <td>{{ p.revenue_Formatted }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-container>
                  <v-row
                    justify="center"
                    class="py-5"
                    v-if="loadingProductReport"
                  >
                    <v-progress-circular
                      :size="50"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import targetService from "@/services/TargetService";

export default {
  data() {
    return {
      loading: false,
      loadingProductReport: false,
      search:"",
      expanded: [],
      report: [],
      reportDetailed: null,
      headers: [
        {
          text: "Merchant",
          align: "start",
          sortable: false,
          value: "merchant",
        },
        { text: "Quantity", value: "quantity_Formatted" },
        { text: "Revenue", value: "revenue_Formatted" },
      ],
    };
  },
  watch: {
    async expanded(val) {
      if (val) {
        this.reportDetailed = null;
        this.loadingProductReport = true;
        this.reportDetailed = await targetService.GetMerchantProductSalesReport(
          val[0].merchantId,
          this.targetInfo.id
        );
        this.loadingProductReport = false;
      }
    },
  },
  computed: {
    targetInfo() {
      return this.$store.getters["filter/result"];
    },
    showProductTable() {
      return this.reportDetailed != null;
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.report = await targetService.GetMerchantProductSalesReportList(
        this.targetInfo.id
      );
      this.loading = false;
    },
  },
};
</script>