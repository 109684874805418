import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store";
import accountService from "@/services/AccountService";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    meta: { requiresLogin: true, roles: [] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Home.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: { requiresLogin: true, roles: [] },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresLogin: false, roles: [] },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/account/Login.vue"),
  },  
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: { requiresLogin: false, roles: [] },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/account/ResetPassword.vue"),
  },      
  {
    path: "/admin",
    name: "admin",
    meta: { requiresLogin: true, roles: ["Administrator"] },
    component: () =>
      import("../views/account/Admin.vue"),
  },
  {
    path: "/precis",
    name: "precis",
    meta: { requiresLogin: true, roles: ["Precis"]},
    component: () =>
      import("../views/Precis.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: { requiresLogin: true, roles: ["Reports"] },
    component: () =>
      import("../views/Reports.vue"),
  },   
  {
    path: "/sales-executive",
    name: "sales-executive",
    meta: { requiresLogin: true, roles: ["Reports"] },
    component: () =>
      import("../views/SalesExecutive.vue"),
  },    
  {
    path: "/pricing",
    name: "pricing",
    meta: { requiresLogin: true, roles: ["Pricing"] },
    component: () =>
      import("../views/Pricing.vue"),
  },  
  {
    path: "/cims",
    name: "cims",
    meta: { requiresLogin: true, roles: ["Cims"]  },
    component: () =>
      import("../views/Cims.vue"),
  },  
  {
    path: "/salesrep",
    name: "salesrep",
    meta: { requiresLogin: true, roles: ["Salesrep"]  },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Salesrep.vue"),
  },  
  {
    path: "/merchantdistributionreport",
    name: "merchantdistributionreport",
    meta: { requiresLogin: true, roles: ["Administrator"] },
    component: () =>
      import("../views/MerchantDistributionReport.vue"),
  }      
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {




  if (to.meta.requiresLogin) {
    //key already exists in local storage?    
    const currentToken = localStorage.getItem("auth-token"); // stash the auth token in localStorage
    if(currentToken !== null){
      store.dispatch("auth/setCredentials", currentToken);
      Vue.prototype.$axios.defaults.headers.common["Authorization"] = "Bearer " + currentToken;
    }
    //check if user is authenticated
    const user = await accountService.CurrentUser();
    if (user !== null) {
      store.dispatch("auth/setUser", user);
    }
    else {
      //otherwise redirect to login
      next({ name: "login" });
      return;
    }

    window.analytics.identify(user.userId, {
      name: user.userName,
      roles: user.roles
    });

  }

  if (to.meta.roles.length) {
    const isInRole = await accountService.CheckRoles(to.meta.roles);
    if(!isInRole){
      const snackbar = { show: true, message: "Unauthorized", errors: to.meta.roles }
      store.dispatch("util/setSnackbar", snackbar)
      next({ name: "home" });
      return;
    }  
  }    

  next();
});

export default router;
