<template>
<v-container>
  <v-row dense justify="center">
  <v-card width="100%">
    <v-container>
    <v-row>
    <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          item-key="id"
          :items-per-page="pageSize"
          :server-items-length="itemsCount"
          @update:options="paginate"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
      
            <v-container>
              <v-row dense align="center">
                
         <v-col cols="1">
                   <v-btn @click="exportdata" color="info" small :loading="loadingDownload" >
                     Download
                <v-icon dark>mdi-download</v-icon>
              </v-btn>
         </v-col>
         <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-text-field
                    dense
                    v-model="search"
                    label="Search on org.no, name or email"
                    class="mr-4"
                    @change="performSearch"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!-- <template v-slot:no-data>
            <v-btn color="primary" @click="Refresh">Uppdatera</v-btn>
          </template> -->
        </v-data-table>
    </v-card-text>
    </v-row>
    </v-container>
  </v-card>
  </v-row>
</v-container>
</template>

<script>
import targetService from "@/services/TargetService";

export default {
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      search: "",
      items: [],
      itemsCount: 0,
      loading: false,
      loadingDownload: false,
      headers: [
      {
        text: "Org.no",
        sortable: false,
        value: "organizationNumber",
        align: 'start'
      },     
      {
        text: "Segment",
        sortable: false,
        value: "segment",
        align: 'start'
      },        
      {
        text: "Name",
        sortable: false,
        value: "name",
        align: 'start'
      },
      {
        text: "Address",
        sortable: false,
        value: "addresslineFull",
        align: 'start'
      },  
      {
        text: "Email",
        sortable: false,
        value: "email",
        align: 'start'
      },
      {
        text: "Tele",
        sortable: false,
        value: "phone",
        align: 'start'
      },   
      {
        text: "SNI",
        sortable: false,
        value: "lineOfBusiness",
        align: 'start'
      },
      {
        text: "#TL",
        sortable: false,
        value: "vehiclesTotalHeavy",
        align: 'start'
      },                                 
        {
        text: "#LL",
        sortable: false,
        value: "vehiclesTotalLight",
        align: 'start'
      },          
      {
        text: "m3 R12",
        sortable: false,
        value: "quantityR12_Formatted",
        align: 'start'
      }, 
      {
        text: "m3 YTD",
        sortable: false,
        value: "quantityYTD_Formatted",
        align: 'start'
      },    
      {
        text: "m3 LYTD",
        sortable: false,
        value: "quantityLYTD_Formatted",
        align: 'start'
      },    
      {
        text: "m3 LY",
        sortable: false,
        value: "quantityLY_Formatted",
        align: 'start'
      },                        
      {
        text: "m3 Potential",
        sortable: false,
        value: "estimatedConsumption_Formatted",
        align: 'start'
      }   
      ]
    };
  },
  mounted() {
    this.Refresh(this.pageNumber, this.pageSize, this.search);
  },
  computed: {
    target() {
      return this.$store.getters["filter/result"];
    },
    showSalesColumns(){
      return this.target.lifecycleType == 1
    },
    filterRequest() {
      return this.$store.getters["filter/filterRequest"];
    },    
  },
  methods: {
    async exportdata(){
      this.loadingDownload = true
      this.$store.dispatch("util/setLoading", {visible: true, text: "Generating Excel file"});
      let report = await targetService.GetTargetExcel(this.target.id, this.filterRequest );

      this.$store.dispatch("util/resetLoading");

      const url_1 = window.URL.createObjectURL(new Blob([report]));
      const link = document.createElement("a");
      link.href = url_1;
      var reportName = "export";
      var filedate = new Date()
        .toISOString()
        .slice(-24)
        .replace(/\D/g, "")
        .slice(0, 14);
      link.setAttribute("download", `${reportName}_${filedate}` + ".xlsx");
      document.body.appendChild(link);
      link.click();
      

      this.loadingDownload = false
      
    },
    async Refresh(pageNumber, pageSize, search) {
      const id = this.target.id;
      this.loading = true;
      let result = null;
      
      if(this.target.lifecycleType === 1){
        result = await targetService.GetTargetCustomersPaginated(
          id,
          pageNumber,
          pageSize,
          search
        );
      }
      else {
          result = await targetService.GetTargetProspectsPaginated(
          id,
          pageNumber,
          pageSize,
          search
        );
      }


      this.items = result.items;
      this.itemsCount = result.totalCount;
      this.hasPreviousPage = result.hasPreviousPage;
      this.hasNextPage = result.hasNextPage;

      this.loading = false;
    },
    async paginate(val) {
      if(this.loading) return
      await this.Refresh(val.page, val.itemsPerPage, this.search);
    },
    async performSearch(val) {
      if(this.loading) return
      await this.Refresh(1, 10, val);
    }
  }
};
</script>