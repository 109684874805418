import Vue from "vue";



export default new (class AccountService {


  async CurrentUser() {
    const path = "/api/accounts/currentuser";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async CheckRoles(roles) {
    const path = `/api/accounts/CheckRoles?roles[]=${roles}`;
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }  



  async Login(username, password) {
    const path = "/api/accounts/login";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: { username: username, password: password },
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response.data)
      });

    return result;
  }







  async Logout() {
    const path = "/api/accounts/logout";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        return error
      });

    return result;
  }



  async Users() {
    const path = "/api/accounts/users";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async Roles() {
    const path = "/api/accounts/roles";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }


  async SaveUser(user) {
    const path = "/api/accounts/saveuser";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: user
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });


    return result;
  }




  async SetPassword(userId, password, passwordOld = null) {
    const path = "/api/accounts/setpassword";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: { userId: userId, password: password, passwordOld: passwordOld },
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async ResetPassword(email) {
    const path = `/api/accounts/resetpassword/${email}`;
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async SetPasswordWithToken(data) {
    const path = "/api/accounts/SetPasswordWithToken";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: data,
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

})();
