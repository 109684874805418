<template>

  <v-navigation-drawer
    v-model="show"
    hide-overlay
    :stateless="show"
    app
    temporary
    width="40%"
    style="min-width:300px"
    right
  >
    

      
      <v-container>
        <v-btn
          class="mt-7"
          color="primary"
          @click="close"
          fab
          absolute
          top
          left
          x-small
        >
          <v-icon x-small>
            mdi-close
          </v-icon>
        </v-btn>
        <v-row v-if="charts">
          <v-col lg="12" cols="12" v-for="(c, i) in charts" :key="i">
                 <v-card color="rgba(0,0,0,0)"  >
            <barchart :id="i" :chart="c" :indexAxis="'y'" :width="150" :height="400" />
            </v-card>

          </v-col>
        </v-row>
        <div id="reportwrapper" class="mt-10">
          <v-row>
          <v-col>
            <merchantproductsalesreport />
          </v-col>
        </v-row>
        </div>
      </v-container>
  </v-navigation-drawer>
 
</template>

<script>

import barchart from "@/components/charts/ChartJs/BarChart.vue"
import merchantproductsalesreport from "@/components/reports/MerchantProductSalesReport.vue"
  export default {
    components:{
      barchart,
      merchantproductsalesreport
    },
    data: () => ({
    }),
    methods: {
      close(){
        this.$store.dispatch("util/setBottomChartSheet", false);
      },
    },
    computed: {
      show(){
         return this.$store.getters["util/showBottomChartSheet"];
      },
      charts(){
         return this.$store.getters["chart/charts"];
      }                       
    }
  }
</script>