<template>
  <v-navigation-drawer v-model="showNavDrawer" left temporary app @input="input">
    <v-list nav dense>
      <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
        <v-list-item to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item to="/target">
          <v-list-item-icon>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Target</v-list-item-title>
        </v-list-item>

        <v-spacer />
        <v-list-item to="/admin">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null
  }),
  computed: {
    showNavDrawer() {
      return this.$store.getters["util/showNavDrawer"];
    }
  },

  watch: {
    group() {
      this.drawer = false;
    }
  },
  methods: {
    input(val) {
      this.$store.dispatch("util/setNavDrawer", val);
    }
  }
};
</script>