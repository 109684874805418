<template>
    <div class="chart-container">
    <canvas :id="chartId"></canvas>
    </div>
</template>

<script>
// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);

export default {
  props: {
    id: { default: null, type: Number},
    chart: { default: null, type: Object },
    loading: { default: null, type: Boolean },
    legend: { default: true, type: Boolean },
    indexAxis: { default: "x", type: String },
    height: { default: null, type: Number },
    width: { default: null, type: Number },

  },
  components: {},
  data: () => ({
    renderedChart: null
  }),
  computed: {
    chartId(){
      return "chartjs-barchart-"+this.id
    },
    chartData(){
      return {
        type: "bar",
        data: {
          labels: this.chart.labels,
          datasets: this.chart.datasets
        },
        options: {
          responsive: true,
          maintainAspectRatio: this.width == null ? true : false,
           plugins: {
            title: {
                display: true,
                text: this.chart.title
            },
            legend: {
              display: this.legend
            }
            
        },
          indexAxis: this.indexAxis,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }
    }

  },
  mounted(){
    //this.init()
    var ctx = document.getElementById(this.chartId);
    

    // eslint-disable-next-line no-undef
    let renderedChart = new Chart(ctx, this.chartData);    

    if(this.width && this.height){
      renderedChart.canvas.style.height = `${this.height}px` 
      renderedChart.canvas.style.width = `this.width}px` 
    }

  },
  methods: {
    // init() {
    //   var ctx = document.getElementById(this.chartId);
    //   // eslint-disable-next-line no-undef
    //   this.renderedChart = new Chart(ctx, this.chartData);
    // }
  }
};
</script>