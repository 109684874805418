<template>
  <v-snackbar v-model="show" color="primary" :timeout="-1" dark>
    <v-container>
      <v-row justify="center" dense>
        <v-progress-circular :size="30" :width="5" color="white" indeterminate>
        </v-progress-circular>
      </v-row>
      <v-row justify="center" dense>
        <span class="white--text"> {{ text }}</span>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    show() {
      return this.$store.getters["util/loading"];
    },
    text() {
      const a = this.$store.getters["util/loadingText"];
      return a;
    },
  },
};
</script>