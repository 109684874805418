//import Vue from "vue";

const getDefaultState = ()  => { 
    return {
      result: null,
      filterRequest: {
        segments: [],
        cardIssuers:[],
        cardTypes:[],
        lifecycleType: 1,
        salesreps: [],
        merchantCountries:[],
        merchantTypes:[],
        merchants:[],
        transactionDateFrom: null,
        transactionDateTo: null,
        geometries:[],
        areaCodes:[],
        areaType: "municipality",
        lineOfBusinesses:[],
        partners:[],
        rewardLevels:[]
      }
    }
  }
  
  const state = getDefaultState();
  
  
   const mutations = {
  
        RESET (state) {
        Object.assign(state, getDefaultState())
        },
  
      SET_RESULT: (state, newValue) => {
        state.result = newValue
      },
      SET_FILTERREQUEST: (state, newValue) => {
        state.filterRequest = newValue
      },
      SET_LIFECYCLE_TYPE: (state, newValue) => {
        state.filterRequest.lifecycleType = newValue
      },      
      SET_SEGMENTS: (state, newValue) => {
        state.filterRequest.segments = newValue
      },
      SET_CARD_ISSUERS: (state, newValue) => {
        state.filterRequest.cardIssuers = newValue
      },      
      SET_CARD_TYPES: (state, newValue) => {
        state.filterRequest.cardTypes = newValue
      },       
      SET_SALESREPS: (state, newValue) => {
        state.filterRequest.salesreps = newValue
      },
      SET_PARTNERS: (state, newValue) => {
        state.filterRequest.partners = newValue
      }, 
      
      SET_REWARD_LEVELS: (state, newValue) => {
        state.filterRequest.rewardLevels = newValue
      },          
    
      SET_GEOMETRIES: (state, newValue) => {
        state.filterRequest.geometries = newValue
      },
      SET_AREA_CODES: (state, newValue) => {
        state.filterRequest.areaCodes = newValue
      },   
      SET_MERCHANT_COUNTRIES: (state, newValue) => {
        state.filterRequest.merchantCountries = newValue
      },         
      SET_MERCHANT_TYPES: (state, newValue) => {
        state.filterRequest.merchantTypes = newValue
      },               
      SET_MERCHANTS: (state, newValue) => {
        state.filterRequest.merchants = newValue
      },
      SET_TRANSACTION_DATE_FROM: (state, newValue) => {
        state.filterRequest.transactionDateFrom = newValue
      },
      
      SET_TRANSACTION_DATE_TO: (state, newValue) => {
        state.filterRequest.transactionDateTo = newValue
      },

      SET_LINE_OF_BUSINESSES: (state, newValue) => {
        state.filterRequest.lineOfBusinesses = newValue
      },      

    }
  
    const actions =  {   
      reset: ({ commit, state }) => {
        commit('RESET')
        return state.message
      },
  
  
      setResult: ({ commit, state }, newValue) => {
        commit('SET_RESULT', newValue)
        return state.message
      },
  
      setFilterRequest: ({ commit, state }, newValue) => {
        commit('SET_FILTERREQUEST', newValue)
        return state.message
      },
  
      setLifecycleType: ({ commit, state }, newValue) => {
        commit('SET_LIFECYCLE_TYPE', newValue)
        return state.message
      },      

      setSegments: ({ commit, state }, newValue) => {
        commit('SET_SEGMENTS', newValue)
        return state.message
      },

      setCardIssuers: ({ commit, state }, newValue) => {
        commit('SET_CARD_ISSUERS', newValue)
        return state.message
      },    
      
      setCardTypes: ({ commit, state }, newValue) => {
        commit('SET_CARD_TYPES', newValue)
        return state.message
      },        

      setPartners: ({ commit, state }, newValue) => {
        commit('SET_PARTNERS', newValue)
        return state.message
      }, 
      
      setRewardLevels: ({ commit, state }, newValue) => {
        commit('SET_REWARD_LEVELS', newValue)
        return state.message
      },   
  
      setSalesreps: ({ commit, state }, newValue) => {
        commit('SET_SALESREPS', newValue)
  
        return state.message
      }, 

      setGeometries: ({ commit, state }, newValue) => {
        commit('SET_GEOMETRIES', newValue)
        return state.message
      },
      setAreaCodes: ({ commit, state }, newValue) => {
        commit('SET_AREA_CODES', newValue)
        return state.message
      },
      setMerchantCountries: ({ commit, state }, newValue) => {
        commit('SET_MERCHANT_COUNTRIES', newValue)
        return state.message
      },
      setMerchantTypes: ({ commit, state }, newValue) => {
        commit('SET_MERCHANT_TYPES', newValue)
        return state.message
      },
      setMerchants: ({ commit, state }, newValue) => {
        commit('SET_MERCHANTS', newValue)
        return state.message
      },
      setTransactionDateFrom: ({ commit, state }, newValue) => {
        commit('SET_TRANSACTION_DATE_FROM', newValue)
        return state.message
      },
      setTransactionDateTo: ({ commit, state }, newValue) => {
        commit('SET_TRANSACTION_DATE_TO', newValue)
        return state.message
      },          
      setLineOfBusinesses: ({ commit, state }, newValue) => {
        commit('SET_LINE_OF_BUSINESSES', newValue)
        return state.message
      },        
      
    }
    const getters = {
      result: state => {
        return state.result
      },    
      filterRequest: state => {
        return state.filterRequest
      },
      isActive: state => {
        return JSON.stringify(state) == JSON.stringify(getDefaultState())
      }
    }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  